
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { dispatchPasswordRecovery } from "@/store/main/actions";

@Component
export default class Login extends Vue {
  public valid = false;
  public email: string = "";
  public appName = appName;

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      dispatchPasswordRecovery(this.$store, { email: this.email });
    }
  }
}
